require("application/application.scss")
require("@rails/ujs").start()
require("turbolinks").start()

require('bootstrap');

$( document ).on('turbolinks:load', function() {
  $("#side-bar-toggel").click(function() {
    $("#accordionSidebar").toggleClass("d-none")
  })
})

